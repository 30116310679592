import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  title = "Øllauget";
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    /* Invoke API function */
    // let response = await fetch(`api/message`);
    // let message = await response.json();

  }

  // onActivate(event) {
  //   window.scroll(0, 0);
  // }
}
